<template>
	<div>
		<s-select-definition
			:def="1423"
			@getDataInitial="GetColumns($event)"
			hidden
		></s-select-definition>
		<s-select-definition
			:def="1424"
			@getDataInitial="GetRows($event)"
			hidden
		></s-select-definition>

		<div class="row">
			<div class="col">
				<v-card dense outlined>
					<v-card-title>
						<h3>{{ "Seleccione Filtros" }}</h3>
					</v-card-title>
					<v-card-title>
						<v-row>
							<v-col cols="4" lg="4" md="4" sm="12">
								<s-select-definition
									label="Tipo Seleccion"
									:def="1318"
									clearable
									v-model="FilterDoll.TypeSelectionFrozen"
									@input="FilterDollSearch()"
								>
									
								</s-select-definition>
							</v-col>
							<v-col cols="4" lg="4" md="4" sm="12">
								<s-select-definition 
									label="Estado" 
									:def="1326"
									clearable
									v-model="FilterDoll.TypeFrozenCategory"
									@input="FilterDollSearch()"
								></s-select-definition>
							</v-col>
						</v-row>
					</v-card-title>
				</v-card>
			</div>
		</div>
		<br>
		<v-card dense outlined>
			<v-card-title>
				<h3>{{ "Ubicación de muñecos: " + WeightSend.toFixed(2)  + ' kg'}}</h3>
			</v-card-title>
			<v-card-text>
				<v-row  align="center" justify="center" v-if="!filter">					
					<v-col
						align="center"
						md="2"
						v-for="item in columns"
						:key="item.DedAbbreviation"
					>
						{{ item.DedDescription }}
						<v-row
							dense
							align="center" justify="center"
							v-for="item2 in rows"
							:key="item2.DedAbbreviation"
						>
							<v-card dense outlined class="mt-2">								
								<template
									v-if="dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription).length > 0"
									>
									<v-card-text
											v-for="( doll, index) in dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription)"
											:disabled="dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription).length > 0 && armed == true"
											@click="sendTable(dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription))"

											style="cursor: pointer;" :key="index"
											
										>
										
										<v-card width="110" height="110">											
											<v-card-subtitle>
												<h4>{{ "N° Jabas: "+ doll.DlpQuantity }}</h4>
													<h5>{{doll.TypeSelectionFrozenName}}</h5>
													<h5>{{ doll.TypeFrozenCategoryName}}</h5>
													<v-chip
														class="ma-2"
														color="red"
														text-color="white"
													>
														{{ doll.DlpCodBase}}
													</v-chip>
											</v-card-subtitle>												
										</v-card>										
									</v-card-text>
								</template>
								<v-card-text
										v-else
									color="success"
									@click="emitLocation({item , item2})"
								>
									<v-row align="center">
										<v-col>
											<v-card width="110" height="110" color="success" ><!-- style="background-color: #6c6c6c;" -->
												
												<v-card-subtitle class="text-xs-center text-h4" style="color: white!important;">
													<h4>{{ item.DedDescription + item2.DedDescription }}</h4>
												</v-card-subtitle>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-row>
					</v-col>
				</v-row>
				<v-row align="center" justify="center" v-if="filter">
					<v-col
						align="center"
						md="2"
						v-for="item in columnsFilter"
						:key="item.DedAbbreviation"
					>
						{{ item.DedDescription }}
						<v-row
							dense
							align="center" justify="center"
							v-for="item2 in rows"
							:key="item2.DedAbbreviation"
						>

						
							<v-card dense outlined >								
								<template
									v-if="dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription).length > 0"
									>
									<v-card-text
											v-for="( doll, index) in dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription)"
											:disabled="dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription).length > 0 && armed == true"
											@click="sendTable(dollStore.filter(doll => doll.DlpPosition == item.DedDescription + item2.DedDescription))"

											style="cursor: pointer;" :key="index"
											
										>
										
										<v-card width="110" height="110">											
											<v-card-subtitle>
												<h4>{{ "N° Jabas: "+ doll.DlpQuantity }}</h4>
													<h5>{{doll.TypeSelectionFrozenName}}</h5>
													<h5>{{ doll.TypeFrozenCategoryName}}</h5>
													<v-chip
														class="ma-2"
														color="red"
														text-color="white"
													>
														{{ doll.DlpCodBase}}
													</v-chip>
											</v-card-subtitle>												
										</v-card>										
									</v-card-text>
								</template>
								<v-card-text
										v-else
									color="success"
									@click="emitLocation({item , item2})"
								>
									<v-row align="center">
										<v-col>
											<v-card width="110" height="110" color="success" ><!-- style="background-color: #6c6c6c;" -->
												
												<v-card-subtitle class="text-xs-center text-h4" style="color: white!important;">
													<h4>{{ item.DedDescription + item2.DedDescription }}</h4>
												</v-card-subtitle>
											</v-card>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-row>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

		<v-dialog 
			v-model="dialogDoll"
			v-if="dialogDoll"
			persistent
		>
			<InfoTable 
				:dolls="dolls"	
				:filter="filter"			
				:IsTransfer="0"
				@CloseDialogInfoTable="CloseDialogInfoTable()"
				@RefreshStore="RefreshStore()"
				>

			</InfoTable>
		</v-dialog>
	</div>
</template>

<script>


	import _armedDoolService from '@/services/FrozenProduction/ArmedDollService'

	import InfoTable from '@/views/FrozenProduction/FrzCtnLinesProcess/InfoTable'

	export default {
		components:{
			InfoTable
		},
		props: {
			// dolls: {
			// 	type: Array,
			// 	default: null
			// },
			armed: {
				type: Boolean,
        		required: false,
				default: false
			},
			
		},
		data() {
			return {
				columns: [],
				rows: [],
				dollStore: [],
				dialogDoll: false,
				dolls: [],
				filter: {},
				FilterDoll:{
                    TypeSelectionFrozen: null,
                    TypeFrozenCategory : null,
                },
                itemsDollFilter:[],
				filter: false,
				columnsFilter: [],
				JabasSend: 0,
                WeightSend: 0,
			}
		},

		methods: {
			CloseDialogInfoTable(){
				this.dialogDoll = false;
				this.clickFilterDoll();

			},
			sendTable(dolls){
				
				 this.dolls = dolls;
				 this.filter = dolls[0];
				 
				 this.dialogDoll = true;
			},
			emitLocation(item){
				this.$emit("emitLocation", item)				
			},
			GetColumns(items) {
				this.columns = items;				
			},

			GetRows(items)
			{
				this.rows = items;				
			},
			listStore(){
				
				_armedDoolService.listStore(this.$fun.getUserID())
				.then((r) => {
					if (r.status == 200) {
						this.dollStore = r.data;
						
						
					}
				})

			},

			FilterDollSearch()
			{
				this.columnsFilter = [];
				let columnF = [];
				if(this.FilterDoll.TypeFrozenCategory != null && this.FilterDoll.TypeSelectionFrozen == null)
				{
					columnF = this.dollStore.filter(doll => 
						doll.TypeFrozenCategory == this.FilterDoll.TypeFrozenCategory);
						
				}
				else if(this.FilterDoll.TypeSelectionFrozen != null && this.FilterDoll.TypeFrozenCategory == null)
				{
					columnF = this.dollStore.filter(doll => 
						doll.TypeSelectionFrozen == this.FilterDoll.TypeSelectionFrozen);
						
				}else
				{
					columnF = this.dollStore.filter(doll => 
						doll.TypeSelectionFrozen == this.FilterDoll.TypeSelectionFrozen
						&& doll.TypeFrozenCategory == this.FilterDoll.TypeFrozenCategory);
				}
				columnF.forEach(column => {
					column.DedDescription = column.DlpCol;
				});
				
				
				
				let ladovisto = {};
				columnF.forEach(column => { 
					let lado = column.DlpCol;
					if(!ladovisto[lado])
					{
						ladovisto[lado] = true;
						this.columnsFilter.push(column);
					}
				})

				this.columnsFilter.sort((a,b) => {
					if(a.DlpCol < b.DlpCol)
					{
						return -1;
					}
					if(a.DlpCol > b.DlpCol)
					{
						return 1;
					}
					return 0;
				});
				
				if(this.FilterDoll.TypeFrozenCategory == null && this.FilterDoll.TypeSelectionFrozen == null) 
				{
					this.filter = false;
				}else{
					this.filter = true;
				}
				
			},

			clickFilterDoll(){
                
				
                _armedDoolService.listDollFilter(this.FilterDoll, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                        // this.itemsDollFilter= r.data
						this.dollStore = r.data;
						this.WeightSend = 0;
                        this.dollStore.forEach(d => {
							this.WeightSend += d.AverageWeightJabaN;
						});
						
                    }
                })
            },
			RefreshStore(){
				this.listStore();
			}
		},
		mounted(){
			// this.listStore();
			this.clickFilterDoll();
		}
	}
</script>